.email-btn {
  font-size: 18px;
  width: 135px;
  height: 48px;
  font-family: "Rubik";
}

.sub-btn,
.sub-btn:not(:disabled) {
  background-color: #7c7ef2;
  border-color: #7c7ef2;
}

.sub-btn:hover {
  background-color: #5d5fef;
  border-color: #5d5fef;
}

.sub-btn:focus,
.sub-btn:active {
  outline: none !important;
  box-shadow: none;
}

.contact-container {
  height: calc(100vh - 150px);
}

.btn-div {
  display: flex;
  align-items: flex-end;
}

.email-text-div {
  margin-bottom: 40px;
}

.email-col {
  margin-top: 100px;
}

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .email-col {
    margin-top: 150px;
  }
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .email-col {
    margin-top: 60px;
  }
}
