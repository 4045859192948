.footer-c {
  display: flex;
  flex-direction: column;
  background-color: #5d5fef;
  justify-content: flex-end;
  height: 65px;
  padding: 20px;
}

.rights {
  display: flex;
  justify-content: center;
  font-size: 14px;
  margin-left: 20px;
  margin-bottom: 0;
  color: #313131;
}

.email-link {
  cursor: pointer;
  color: white;
  font-family: Rubik;
  font-style: normal;
}

a,
a:hover {
  color: white;
}

.contact-link {
  text-decoration: none;
}

/* Medium screens */
@media all and (max-width: 1000px) {
}
