.login-register-h1 {
  margin-top: 10px;
  color: #4d4d4d;
  font-family: "Solway";
  font-weight: bold;
}

.login-register-p {
  font-size: 18px;
  color: #4d4d4d;
  font-family: "Rubik";
}

.login-register-toolbar {
  justify-content: center !important;
  margin-top: 50px;
}

.login-btn,
.login-btn:not(:disabled) {
  background-color: #f2b84b !important;
  border-color: #f0ac2b !important;
}

.login-btn:hover {
  background-color: #f0ac2b !important;
  border-color: #f0ac2b !important;
}

.register-btn,
.register-btn:not(:disabled) {
  background-color: #7c7ef2 !important;
  border-color: #5d5fef !important;
}

.register-btn:hover {
  background-color: #5d5fef !important;
  border-color: #5d5fef !important;
}

.landing-btn {
  border: none;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 8px;
  width: 250px;
  height: 180px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 700;
  font-size: 34px !important;
  line-height: 41px;
  text-align: center;
  margin-bottom: 20px;
  margin-right: 20px;
}
