.form-container {
  width: 70%;
}

.form-control:not(active) {
  color: black !important;
}

.register-h1 {
  margin-top: 10px;
  color: #4d4d4d;
  font-family: "Solway";
  font-weight: bold;
  cursor: default;
}

.register-p {
  font-size: 18px;
  color: #4d4d4d;
  font-family: "Rubik";
  cursor: default;
}

.information-text {
  font-size: 12px;
}

.registerbtn,
.registerbtn:not(:disabled) {
  margin-top: 30px;
  width: 425px;
  background-color: #7c7ef2;
  border-color: #7c7ef2;
  font-family: "Rubik";
}

.registerbtn:hover {
  background-color: #5d5fef;
  border-color: #5d5fef;
}

.flag-image{
  width: 40px;
  height: 25px;
  border-radius: 3px; 
  margin-right: 10px;
  cursor: pointer;
  transition: opacity 0.3s, border 0.3s;
}

.flag-image.selected {
  opacity: 1;
  border: 1px solid #000000; /* Green border for the selected language */
  border-radius: 5px;
}

.flag-image.unselected {
  opacity: 0.7;
  border: none;
}