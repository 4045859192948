.user-container {
  display: flex;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.row {
  margin: 0%;
}

.section-header{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 40px;
  padding: 0;
}

.chip{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FF6665;
  color: white;
  font-weight: 600;
  padding: 2px 10px 2px 10px;
  border-radius: 3px;
  margin-left: 10px;
}

.category-div {
  display: flex;
  flex-direction: column;
  padding: 4%;
  height: 100%;
  overflow: hidden;
}

.teacher-speaker-bubble-div {
  background-color: #f2b84b;
  padding: 8%;
  border-radius: 35px;
  width: auto;
}

.teacher-welcome {
  font-family: Solway;
  font-style: normal;
  font-weight: bold;
  color: #ffffff;
}

.teacher-img-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 65%;
}

.teacher-img {
  max-height: 280px;
  align-items: center;
}

.theme-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap; /* Prevents wrapping */
}

.theme-row::-webkit-scrollbar {
  height: 5px; /* Height of the horizontal scrollbar */
}

/* Medium screens */
@media all and (max-width: 1300px) {
  .welcome-div {
    max-width: fit-content;
  }

  .teacher-img {
    max-width: 100%;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .user-practice-btn {
    width: 350px;
  }
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
}
