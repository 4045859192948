.mascot-container {
  padding-top: 50px;
  padding-bottom: 200px;
}

.point-circle {
  display: inline-block;
  background-color: #28a3ee;
  border-radius: 16px;
  height: 40px;
  width: 90px;
  padding-top: 3px;
  margin-top: 5px;
  margin-left: 20px;
}

.point-container {
  display: flex;
  justify-content: space-between;
  margin: 20px 50px 20px 0;
}

.points-div {
  display: flex;
}

.mascot-card {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 8px;
}

.mascot-name {
  font-family: "Solway";
  font-weight: bold;
  color: #4d4d4d;
  font-size: 20px;
}

.top-point-text {
  color: white;
  font-size: 24px;
  font-family: "Rubik";
}

.buy-mascot-btn,
.buy-mascot-btn:not(:disabled) {
  width: 150px;
  height: 45px;
  background-color: #f2b84b;
  border-color: #f7ac22;
  font-family: "Rubik";
}

.buy-mascot-btn:hover {
  background-color: #f0ac2b;
  border-color: #f0ac2b;
}

.point-text {
  color: #4d4d4d;
  font-size: 18px;
  font-family: "Rubik";
  margin-bottom: 30px;
}

.pick-mascot-btn,
.pick-mascot-btn:not(:disabled) {
  background-color: #7c7ef2;
  border-color: #7c7ef2;
  font-family: "Rubik";
  width: 150px;
  height: 48px;
}

.pick-mascot-btn:hover {
  background-color: #5d5fef;
  border-color: #5d5fef;
}

.active-mascot-btn {
  border: none;
  height: 45px;
  background: none;
  outline: 0 !important;
  color: #4d4d4d;
  font-style: italic;
  visibility: hidden;
}

.go-back-btn-container {
  display: flex;
  margin-top: 50px;
  margin-right: 50px;
  justify-content: flex-end;
}

.go-back-btn,
.go-back-btn:not(:disabled) {
  background-color: #7c7ef2;
  border-color: #696bf5;
  font-family: "Rubik";
  width: 135px;
  height: 45px;
}

.go-back-btn:hover {
  background-color: #5d5fef;
  border-color: #5d5fef;
}
