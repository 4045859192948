.ranking-user-welcome-h1{
  font-family: Solway;
  font-size: 36px;
  font-style: normal;
  font-weight: bold;
  margin-top: 25px;
  margin-bottom: 25px;
}

.ranking-table-header {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 18px !important;
  line-height: 21px;
  letter-spacing: -0.02em;
  color: #8492a6 !important;
  background-color: #edf2f7;
}

.thead-light {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.02em;
  color: #27272e;
}

.ranking-user-container {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
  align-items: center;
}

.ranking-table-div {
  width: 600px;
}

.ranking-rank {
  width: 100px;
  text-align: center;
}

.ranking-user-info-col {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  padding-left: 10%;
  padding-right: 10%;
}

.ranking-h1-table {
  display: flex;
  flex-direction: column;
}

.button-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.filter-bnt {
  margin-top: 10px;
  width: 180px;
  height: 48px;
  background-color: #edf2f7;
  border-color: #edf2f7;
  font-family: Rubik;
  font-size: 18px;
  border-radius: 10px;
  color: #4d4d4d;
}

.filter-bnt:hover {
  background-color: #47b0f1;
  border-color: #47b0f1;
}

.filter-bnt:active {
  background-color: #47b0f1;
  border-color: #47b0f1;
}

.user_information {
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  padding-left: 200px;
}

.user-info-h1 {
  font-family: Solway;
  font-size: 36px;
  font-style: normal;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.clicked {
  background-color: #47b0f1;
  color: #f7fafc;
}

/* Medium screens */
@media all and (max-width: 1300px) {
  .table-div {
    width: fit-content;
  }

  .user-card {
    max-width: fit-content;
  }
}

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1081px) and (-webkit-min-device-pixel-ratio: 1) {
  .ranking-user-info-col {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
  }

  .user_information {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0%, 10%, 10%, 10%;
    padding: 10px, 10px, 10px, 10px;
  }
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .user-practice-btn {
    width: 350px;
  }
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
}

/* Small screens */
@media all and (max-width: 500px) {
  .table-div {
    max-width: fit-content;
  }
}
