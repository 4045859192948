.rewardi-container {
  padding-top: 50px;
  padding-bottom: 200px;
}

.rewardi-point-container {
  display: flex;
  justify-content: space-between;
  margin: 20px 50px 20px 0;
}

.reward-card {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 8px;
  width: 200px;
  height: 300px;
  margin-left: 10px;
  margin-right: 10px;
}

.filter-btn,
.filter-btn:not(:disabled) {
  background-color: #7c7ef2;
  border-color: #696bf5;
  font-family: "Rubik";
  width: 170px;
  height: 45px;
}

.filter-btn:hover {
  background-color: #5d5fef;
  border-color: #5d5fef;
}

.go-quiz-btn,
.go-quiz-btn:not(:disabled) {
  background-color: #f2b84b;
  border-color: #f7ac22;
  font-family: "Rubik";
  width: 135px;
  height: 45px;
  margin-right: 15px;
}

.go-quiz-btn:hover {
  background-color: #f0ac2b;
  border-color: #f0ac2b;
}

.locked-card {
  filter: blur(7px);
  opacity: 0.4;
}

.locked-card:hover {
  filter: blur(1px);
}

.header-circle {
  display: inline-block;
  background-color: #f0ac2b;
  border-radius: 16px;
  height: 40px;
  width: 200px;
  margin-top: 5px;
  margin-left: 20px;
}

.selector {
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
