/* StepProgressBar.css */
.progress-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
  position: relative;
  width: 300px;
}

.progress-bar-background {
  position: absolute;
  top: 50%;
  width: 94%;
  height: 2px;
  background-color: #e0e0e0;
  z-index: -1;
}

.progress-bar {
  height: 2px;
  background-color: #6ec248;
  transition: width 0.4s ease;
}

.step {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #e0e0e0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.step.completed {
  background-color: #6ec248;
}

.step.current {
  background-color: white;
  border: 3px solid #6ec248;
}

.step.last-step {
  background-color: #ffffff;
  width: 40px;
  height: 40px;
  border: 3px solid #6ec248;
}
