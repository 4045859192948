table {
  table-layout: fixed;
}

.table-header {
  font-family: Solway;
  font-style: normal;
  width: 200px;
  font-size: 18px !important;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #8492a6 !important;
}

.table-header-amount {
  width: 100px;
}

.body-text {
  font-size: 16px !important;
  color: #4d4d4d;
}

.category-icon {
  margin-right: 10px;
}

.td {
  padding-right: 10px;
}
