.navbar {
  background-color: #ff6665;
  height: 70px;
  padding-left: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.logo-container {
  background-color: #ff6665;
  width: 70px;
  height: 70px;
}

.logo {
  width: 40px;
  margin-left: 15px;
  margin-top: 15px;
}

.logo:hover {
  cursor: pointer;
}

.points-col {
  position: absolute;
  margin-left: 90px;
  margin-top: 8px;
  width: auto !important;
}

.coins-col {
  position: absolute;
  margin-left: 150px;
  margin-top: 8px;
  width: auto !important;
}

.navbar-brand {
  color: #f4c46b;
  font-family: "Solway";
  font-size: 1rem;
}

.home-btn,
.home-btn:not(:disabled) {
  background-color: #f2b84b;
  border-color: #f7ac22;
  font-family: "Rubik";
  margin-right: 12px;
}

.home-btn:hover {
  background-color: #f0ac2b;
  border-color: #f0ac2b;
}

.logout-btn-postlogin,
.logout-btn-postlogin:not(:disabled) {
  background-color: #ff6665;
  border-color: #ff6665;
  font-family: "Rubik";
}

.logout-btn-postlogin:hover {
  background-color: #ff4443;
  border-color: #ff4443;
}

.contact-btn,
.contact-btn:not(:disabled) {
  background-color: #7c7ef2;
  border-color: #696bf5;
  font-family: "Rubik";
  margin-right: 12px;
  text-decoration: none;
}

.contact-btn:hover {
  background-color: #5d5fef;
  border-color: #5d5fef;
}

.contact-btn:focus,
.contact-btn:active {
  outline: none !important;
  box-shadow: none;
}
