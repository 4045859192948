.category-button {
  display: flex;
  flex-direction: row;
  font-family: Solway;
  font-style: normal;
  font-weight: bold;
  color: #000000;
  height: 80px;
  border-color: #aeaff7;
  border-width: 3px;
  border-radius: 8px;
  padding: 0%;
  margin: 20px 0% 0%;
}

.category-button:hover {
  color: #000000;
  border-color: #5d5fef;
}

.left-div {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-items: center;
  height: 100%;
  min-width: 370px;
  border-radius: 4px 0px 0px 4px;
  background-color: #bebff9;
  font-size: xx-large;
}

.category-icon {
  margin: 0% 4% 0%;
}

.level-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  background-color: #ffd27c;
  border-style: solid;
  border-color: #5d5fef;
  border-width: 2px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: 4%;
  font-size: x-large;
}

.right-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 0% 2% 0%;
  background-color: #d7d8fb;
  border-radius: 0px 4px 4px 0px;
  font-size: 1.3vw;
}

p {
  margin-bottom: 0%;
  padding: 0%;
}

.progressbar-div {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.progressbar {
  height: 16px;
  width: 30%;
  background-color: #bebff9;
  border-style: solid;
  border-color: #5d5fef;
  border-width: 1px;
  border-radius: 3px;
  margin-left: 1px;
}
