.form-container {
  width: 450px;
}

.form-control:not(active) {
  color: black !important;
}

.welcome-h1 {
  margin-top: 10px;
  color: #4d4d4d;
  font-family: "Solway";
  font-weight: bold;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: -0.02em;
}

.welcome-p {
  font-size: 18px;
  color: #4d4d4d;
  font-family: "Rubik";
}

.forgot-text {
  font-size: 13px;
  color: #4d4d4d;
  font-family: "Rubik";
  line-height: 21px;
  letter-spacing: -0.02em;
}

.forgot-text:hover {
  color: black;
  cursor: pointer;
}

.upperform {
  margin-top: 20px;
  font-family: "Rubik";
  color: #4d4d4d;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.02em;
}

.login-button,
.login-button:not(:disabled) {
  margin-top: 20px;
  width: 425px;
  font-family: "Rubik";
  background-color: #7c7ef2;
  border-color: #7c7ef2;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.02em;
}

.login-button:hover {
  background-color: #5d5fef;
  border-color: #5d5fef;
}
