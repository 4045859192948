.navbar-postlogin {
  background-color: #ff6665;
  height: 70px;
  cursor: default;
}

.logo-container {
  background-color: #ff6665;
}

.logo:hover {
  cursor: pointer;
}

.logo-postlogin {
  width: 40px;
  margin-left: 5px;
  margin-top: 15px;
  cursor: pointer;
}

.app-name-col-postlogin {
  position: absolute;
  margin-left: 90px;
  margin-top: 8px;
  width: auto !important;
}

.navbar-brand-postlogin {
  color: white;
  font-family: "Solway";
  font-size: 1.15rem;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.02em;
}

.postlogin-toolbar {
  margin-top: 15px;
  justify-content: end;
}

.ranking-btn-postlogin,
.ranking-btn-postlogin:not(:disabled) {
  background-color: #67bef3;
  border-color: #67bef3;
  font-family: "Rubik";
  margin-right: 12px;
}

.ranking-btn-postlogin:hover {
  background-color: #28a3ee;
  border-color: #28a3ee;
}

.ranking-btn-postlogin:focus {
  outline: none !important;
  box-shadow: none !important;
}

.home-btn-postlogin,
.home-btn-postlogin:not(:disabled) {
  background-color: #f2b84b;
  border-color: #f7ac22;
  font-family: "Rubik";
  margin-right: 12px;
}

.home-btn-postlogin:hover {
  background-color: #f0ac2b;
  border-color: #f0ac2b;
}

.home-btn-postlogin:focus {
  outline: none !important;
  box-shadow: none !important;
}

.logout-btn-postlogin,
.logout-btn-postlogin:not(:disabled) {
  background-color: #ff6665;
  border-color: #ff6665;
  font-family: "Rubik";
}

.logout-btn-postlogin:hover {
  background-color: #ff4443;
  border-color: #ff4443;
}

.contact-btn,
.contact-btn:not(:disabled) {
  background-color: #7c7ef2;
  border-color: #696bf5;
  font-family: "Rubik";
  text-decoration: none;
  margin-right: 12px;
}

.contact-btn:hover {
  background-color: #5d5fef;
  border-color: #5d5fef;
}

.contact-btn:focus,
.contact-btn:active {
  outline: none !important;
  box-shadow: none;
}
