.sidebar-container {
  color: #ffffff;
  background: #7c7ef2;
  padding: 25px;
  width: 500px;
  height: 100%;
}

.sidebar-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}

.user-mascot-div {
  display: flex;
  flex-direction: column;
  max-width: 85%;
  align-items: center;
}

.user-mascot-img-sb {
  width: 150px;
  border-radius: 50%;
  margin-right: 15px;
}

.user-info-div {
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.username-h1 {
  font-family: Solway;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  letter-spacing: -0.02em;
  color: #ffffff;
}

.user-change-mascot-btn-sb {
  width: 105px;
  height: 25px;
  background-color: #f2b84b;
  border-color: #f2b84b;
  border-radius: 8px;
  font-family: Solway;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  padding: 0;
}

.user-change-mascot-btn-sb:hover {
  background-color: #f0ac2b;
  border: none;
}

.user-strike-div {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  height: wrap;
  width: 450px;
  background-color: #bebff9;
  border-radius: 8px;
  padding: 20px;
  padding-bottom: 25px;
  margin-top: 5%;
}

.user-strike-h2-sb,
.user-badge-h2-sb {
  font-family: Solway;
  font-style: normal;
  font-weight: bold;
  color: #ffffff;
  font-size: 36px;
  letter-spacing: -0.02em;
}

.table-div {
  background-color: #fbfbff;
  width: 410px;
  padding-left: 10px;
  padding-right: 10px;
}

.user-bagde-div {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  height: 255px;
  width: 450px;
  background-color: #bebff9;
  border-radius: 8px;
  padding: 20px;
  padding-bottom: 25px;
  margin-top: 5%;
}

.sidebarP {
  color: #ffffff;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.02em;
  display: unset;
}

.unlocked-badge {
  margin: 6px;
  max-height: 120px;
  max-width: 120px;
  display: inline-block;
}

.locked-badge {
  margin: 6px;
  max-height: 120px;
  max-width: 120px;
  filter: blur(7px);
  opacity: 0.4;
  display: inline-block;
}

.locked-badge:hover {
  filter: blur(1px);
}

.badge-col {
  padding-top: 20px;
  justify-content: center;
}

.reward-image-container {
  display: inline-block;
}

.reward_container {
  background-color: #f2b84b;
  padding: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.reward_message {
  color: #ffffff;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  letter-spacing: -0.02em;
}

.see_reward_btn {
  background-color: #7c7ef2;
  border-color: #5d5fef;
  font-family: "Rubik";
}

.see_reward_btn:hover {
  background-color: #5d5fef;
  border-color: #5d5fef;
}

.close_btn {
  background-color: #ff8887;
  border-color: #ff6665;
  font-family: "Rubik";
  margin-left: 10px;
}

.close_btn:hover {
  background-color: #ff6665;
  border-color: #ff6665;
}

.selector {
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.pointer-cursor {
  cursor: pointer;
  color: #ffffff;
}

.reward-image-container {
  display: inline-block;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #d9d9fb;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5d5fef;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5d5fef;
}

/* Medium screens */
/*
@media all and (max-width: 1350px) {
  .sidebar-container {
    overflow-y: scroll;
    transition: max-width 0.5s;
    height: 100vmax;
    overflow-x: hidden;
  }
}*/

/* Small screens */
@media all and (max-width: 500px) {
  .table-div {
    max-width: fit-content;
  }
}
