.email-container {
  margin-top: 100px;
}

.form-container {
  width: 450px;
}

.form-control:not(active) {
  color: black !important;
}

.reset-h1 {
  margin-top: 10px;
  color: #4d4d4d;
  font-family: "Solway";
  font-weight: bold;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: -0.02em;
}

.reset-p {
  font-size: 13px;
  color: #4d4d4d;
  font-family: "Rubik";
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.02em;
}

.upperform {
  margin-top: 20px;
  font-family: "Rubik";
  color: #4d4d4d;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.02em;
}

.send-button,
.send-button:not(:disabled) {
  margin-top: 50px;
  width: 425px;
  font-family: "Rubik";
  background-color: #7c7ef2;
  border-color: #7c7ef2;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.02em;
}

.send-button:hover {
  background-color: #5d5fef;
  border-color: #5d5fef;
}

.go-back-button,
.go-back-button:not(:disabled) {
  position: absolute;
  bottom: 50px;
  right: 50px;
  font-family: "Rubik";
  background-color: #7c7ef2;
  border-color: #7c7ef2;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: -0.02em;
}

.go-back-button:hover {
  background-color: #5d5fef;
  border-color: #5d5fef;
}
