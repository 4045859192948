.multiple-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
  padding: 0;
  justify-content: space-around;
}

.question-row {
  box-sizing: border-box;
  max-height: inherit;
  display: flex;
}

.navbar-brand {
  display: flex;
  flex-direction: row;
}

.coin-logo{
  padding-left: 15px;
}

/* is applied on all pages:( */

/* .row {
  --bs-gutter-x: 0 !important;
} */

.question-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  max-width: 600px;
  margin: 10px;
}

.question-img-col {
  margin-right: 100px;
  margin-top: 100px;
  max-width: fit-content;
}

.question-img {
  max-height: 300px;
  max-width: 600px;
  align-self: center;
  margin-bottom: 20px;
}

.quiz-mascot-img {
  width: 291px;
}

.mascot-col {
  margin-left: 100px;
  margin-top: 80px;
  position: relative;
}

.category-h1 {
  margin-bottom: 30px;
  font-style: normal;
  margin-top: 10px;
  color: #4d4d4d;
  font-family: "Solway";
  font-weight: bold;
  cursor: default;
}

.title-card {
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-radius: 16px;
  margin-bottom: 21px;
}

.question-description {
  font-size: 18px;
  line-height: 26px;
  color: #4d4d4d;
  margin-top: 10px;
  font-weight: 400;
  letter-spacing: -0.02em;
  text-align: left;
}

.options-form {
  display: flex;
  justify-content: flex-start;
}

.option-card {
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24),
    0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  border-radius: 16px;
}

.form-check-label {
  margin-left: 8px;
  font-size: 16px;
  line-height: 20px;
  color: #4d4d4d;
  width: 100%;
  text-align: left;
}

.quiz-btn {
  margin: 15px;
  font-size: 18px;
  width: 135px;
  height: 48px;
  font-family: "Rubik";
}

.hint-btn,
.hint-btn:not(:disabled) {
  background-color: #f2b84b;
  border-color: #f7ac22;
}

.hint-btn:focus,
.hint-btn:active {
  outline: none !important;
  box-shadow: none;
}

.hint-btn:hover {
  background-color: #f0ac2b;
  border-color: #f0ac2b;
}

.close-hint-btn,
.close-hint-btn:not(:disabled) {
  background-color: #ff6665;
  border-color: #ff6665;
}

.close-hint-btn:hover {
  background-color: #ff4443;
  border-color: #ff4443;
}

.close-hint-btn:focus,
.close-hint-btn:active {
  outline: none !important;
  box-shadow: none;
}

.sub-btn,
.sub-btn:not(:disabled) {
  background-color: #7c7ef2;
  border-color: #7c7ef2;
}

.sub-btn:hover {
  background-color: #5d5fef;
  border-color: #5d5fef;
}

.sub-btn:focus,
.sub-btn:active {
  outline: none !important;
  box-shadow: none;
}

.sub-btn:disabled {
  background-color: #d9d9fb !important;
  border: #d9d9fb !important;
}

.sub-btn-disabled:hover {
  background-color: #d9d9fb;
  border: #d9d9fb;
}

.expl-btn,
.expl-btn:not(:disabled) {
  width: 174px;
  height: 48px;
  background-color: #f2b84b;
  border-color: #f7ac22;
}

.expl-btn:hover {
  background-color: #f0ac2b;
  border-color: #f0ac2b;
}

.expl-btn:focus,
.expl-btn:active {
  outline: none !important;
  box-shadow: none;
}

.close-expl-btn,
.close-expl-btn:not(:disabled) {
  width: 200px;
  height: 48px;
  background-color: #ff6665;
  border-color: #ff6665;
}

.close-expl-btn:hover {
  background-color: #ff4443;
  border-color: #ff4443;
}

.close-expl-btn:focus,
.close-expl-btn:active {
  outline: none !important;
  box-shadow: none;
}

.next-btn,
.next-btn:not(:disabled) {
  width: 200px;
  height: 48px;
  background-color: #7c7ef2;
  border-color: #7c7ef2;
}

.next-btn:hover {
  background-color: #5d5fef;
  border-color: #5d5fef;
}

.next-btn:focus,
.next-btn:active {
  outline: none !important;
  box-shadow: none;
}

.btn-div {
  display: flex;
  justify-content: flex-end;
}

.btn-icon {
  margin-left: 4px;
  margin-bottom: 4px;
}

.speakboble {
  position: absolute;
  z-index: 0;
  top: -134px;
  right: 141px;
}

.no-hint {
  align-items: center;
}

.speakboble-text {
  font-family: Rubik;
  font-size: 18px;
  font-weight: 800;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  z-index: 1;
  top: -83px;
  right: 199px;
  width: 200px;
  height: 170px;
  color: #4d4d4d;
  line-height: 26px;
}

.feedback-div {
  margin: 10%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.feedback-btn {
  font-family: Rubik;
  font-size: 16px;
  font-weight: 500;
  background-color: #ff6665;
  border-color: #ff6665;
}

.feedback-btn:hover {
  background-color: #ff4443;
  border-color: #ff4443;
}

h2 {
  font-family: Solway;
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 41px;
  letter-spacing: -0.02em;
  color: #4d4d4d;
}

.explanation-div {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  padding: 20px 20px 20px 20px;
}

.explanation-text {
  display: flex;
  max-width: 800px;
  font-family: Rubik;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #4d4d4d;
}

.explanation-img {
  padding-bottom: 10px;
  max-height: 300px;
  align-self: center;
}

.form-control:not(active) {
  color: white;
}

.form-control:focus {
  color: black;
}

.form-check {
  display: flex;
  width: 540px;
}

.wrong-answer {
  background-color: #ff6665;
  border-radius: 6px;
}

.correct-answer {
  background-color: #64ed82;
  border-radius: 6px;
}

.form-check:hover {
  display: flex;
  background-color: #d9d9fb;
  border-radius: 6px;
  border-left: 2px;
}

.feedback-btn:active,
.feedback-btn:focus {
  outline: none !important;
  box-shadow: none;
  background-color: #ff4443;
  border-color: #ff4443;
}

.form-check-input:checked {
  background-color: #f2b84b;
  border-color: #f2b84b;
}

.form-check-input:focus {
  box-shadow: none;
}

.form-check:checked {
  background-color: #5d5fef !important;
}

.reward-popup-container {
  background-color: #f2b84b;
  padding: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: 20px;
  height: 100px;
}

/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
}

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .question-row {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: inherit;
  }
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
}

.fractionbr {
  content: "";
  margin: -1%;
  display: block;
}

.fractioncontainer {
  margin-top: 9px;
  line-height: 20px !important;
}
